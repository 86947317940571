// src/components/layout/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import { MapPin, Mail, Phone } from "lucide-react";

import LegalPopup from "../common/LegalPopup";
import SocialMedia from "../common/SocialMedia";

import foxLogoSmall from "../../assets/home-hero-fox.jpg";
// Christmas time only
// import foxLogoSmall from "../../assets/logo_fox_smith_associates_christmas_1_compressed_cropped.png";
import StylizedText from "./StylizedText";

const PhoneNumber = ({ number }) => {
  // Remove any non-digit characters from the number
  const cleanNumber = number.replace(/\D/g, "");

  return (
    <a href={`tel:${cleanNumber}`} className="text-black hover:underline">
      {number}
    </a>
  );
};

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white text-black py-12 border-t border-gray-300">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="space-y-3 mb-z6">
            <div>
              <Link to="/" className="flex items-center mr-2">
                <img
                  src={foxLogoSmall}
                  alt="Fox Smith Logo"
                  className="w-8 h-8 mr-1 rounded-full opacity-80"
                />
                <StylizedText text="Fox Smith" color="black" />
              </Link>
            </div>

            <div className="space-y-3 mb-z6 mr-1">
              <div className="flex items-center text-black hover:text-gray-600 m-1">
                <Mail className="px-1 mr-2" />
                <span>info@foxsmith.co.uk</span>
              </div>
              <div className="flex items-center text-black hover:text-gray-600 m-1">
                <Phone className="px-1 mr-2" />
                <span>
                  <PhoneNumber number="0044 1628 276 130" />
                </span>
              </div>
              <div className="flex items-center text-black hover:text-gray-600 m-1">
                <MapPin className="px-1 mr-2" />
                <Link
                  to="https://www.google.com/maps/place/43+High+St,+Marlow+SL7+1BA/@51.5706497,-0.7752144,17z/data=!3m1!4b1!4m6!3m5!1s0x487689c3f0b71565:0xc5177e8095007cb4!8m2!3d51.5706497!4d-0.7752144!16s%2Fg%2F11cnchpvs5"
                  className="tracking-wider text-sm font-semibold inline-block"
                >
                  <span>43 High Street, Marlow</span>
                  <br/>
                  <span>SL7 1BA, UK</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-1 uppercase tracking-wider py-2">
              Legal
            </h3>
            <ul className="space-y-3">
              {["Privacy Policy", "Terms and Conditions", "Cookie Policy"].map(
                (item) => (
                  <li key={item}>
                    <LegalPopup type={item.toLowerCase().replace(/\s/g, "-")}>
                      <span className="flex text-base text-black hover:text-gray-600 transition duration-300">
                        {item}
                      </span>
                    </LegalPopup>
                  </li>
                )
              )}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-1 uppercase tracking-wider py-2">
              Follow Us
            </h3>
            <SocialMedia alignment="left" />
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-200 text-center">
          <p className="text-sm text-gray-600">
            &copy; {currentYear} Fox Smith. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
