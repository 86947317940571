import React from 'react';

const StylizedText = ({ text, className = '', color = 'white' }) => {
  return (
    <span
      className={`inline-block font-serif ${
        color === 'black' ? 'text-black' : 'text-white'
      } ${className}`}
    >
      <style>
        {`
          .title-text {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-weight: 200;
            text-align: center;
            line-height: 1.2;
            white-space: nowrap;
          }

          .first-letter {
            font-size: 1.3em;
            font-weight: 300;
          }
        `}
      </style>
      {text.split(' ').map((word, wordIndex) => (
        <span key={wordIndex} className="title-text inline-block mr-3">
          <span className="first-letter font-serif">{word.charAt(0)}</span>
          {word.slice(1)}
          {wordIndex < text.split(' ').length - 1 ? ' ' : ''}
        </span>
      ))}
    </span>
  );
};

export default StylizedText;