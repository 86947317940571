import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import aboutHeroImage from "../assets/marlow-hero.jpg";
import StylizedText from "../../src/components/layout/StylizedText";


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative h-screen">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${aboutHeroImage})`,
            filter: "grayscale(100%)",
          }}
        ></div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative h-full flex items-start">
          <div className="container mx-auto px-4 pt-[25vh] text-white">
            <div className="max-w-3xl px-6 py-3">
              <h1 className="text-2xl font-bold mb-6 leading-tight">
                <StylizedText text="About Fox Smith Associates" />
              </h1>
              <p className="text-xl mb-12 max-w-2xl">
              Personalised Service, Expert Tax Solutions
              </p>
              <Link
                to="/contact"
                className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold inline-block"
              >
                Connect With Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center">
            <StylizedText text="Our Story" color="black" />
          </h2>
          <div className="max-w-3xl mx-auto">
            <p className="text-gray-700 mb-6 text-lg leading-relaxed">
            The founders of Fox Smith bring experience from leading UK accountancy firms and have been raised in entrepreneurial families. This unique combination provides them with a profound understanding of the entire business cycle, wealth preservation and succession planning.  Including what it takes to start, build, grow, successfully exit a business and pass wealth to the next generation in a tax efficient manner. 
            </p>
            <p className="text-gray-700 mb-6 text-lg leading-relaxed">
Founded in 2016, Fox Smith sought to depart from the outdated and stereotypical views of traditional accountancy firms and the impersonal nature of large, consolidated corporations. The firm focuses on bespoke expert advice, placing client service at its core, genuinely partnering with clients to become their trusted adviser.
            </p>
            <p className="text-gray-700 mb-6 text-lg leading-relaxed">
Our expertise is extensive in scope and thorough in detail. Our team possesses qualifications from all the major accountancy, tax and trust regulatory bodies including the Institute of Chartered Accountants in England and Wales (ICAEW), the Association of Chartered Certified Accountants (ACCA), the Chartered Institute of Taxation (CIOT) and the Society of Trust and Estate Practitioners (STEP).
            </p>
            <p className="text-gray-700 mb-6 text-lg leading-relaxed">
We don’t have a typical client  however the majority of our clients have the same mindset, they require high quality, accurate accounting, bespoke tax advice, and above all exceptional service. 
            </p>
            <p className="text-gray-700 mb-6 text-lg leading-relaxed">
We only accept clients where we know we can provide service to the highest of standards and that's why they choose to work with Fox Smith.
            </p>
          </div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {[
              {
                title: "Integrity",
                description:
                  "We uphold the highest ethical standards in all our dealings.",
              },
              {
                title: "Excellence",
                description:
                  "We strive for excellence in every aspect of our work.",
              },
              {
                title: "Client-Centric",
                description:
                  "Our clients success is at the heart of everything we do.",
              },
            ].map((value, index) => (
              <div
                key={index}
                className="border border-gray-200 p-8 hover:shadow-xl transition duration-300 bg-white"
              >
                <h3 className="text-xl font-semibold mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Team Section - Updated */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center">
            Meet Our Experts
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
            <div className="text-center">
              {/* <img
                src={businessAdvisor}
                alt="Managing Director"
                className="w-64 h-64 object-cover rounded-full mx-auto mb-6"
              /> */}
              <h3 className="text-xl font-semibold mb-2">Antony Smith</h3>
              <p className="text-gray-600 mb-4">Managing Director</p>
              <p>
                <a href="https://www.linkedin.com/in/antony-smith-ba-hons-fcca-cta-tep-83a875104/">LinkedIn</a>
              </p>
              {/* <p className="text-gray-700 mb-6">
                Michael brings a wealth of industry knowledge to our team. With
                his sharp analytical skills and strategic mindset, he excels at
                guiding businesses through complex financial decisions and
                growth strategies.
              </p> */}
            </div>
            <div className="text-center">
              {/* <img
                src={businessAdvisor}
                alt="Managing Director"
                className="w-64 h-64 object-cover rounded-full mx-auto mb-6"
              /> */}
              <h3 className="text-xl font-semibold mb-2">Simon Jacobs</h3>
              <p className="text-gray-600 mb-4">Tax Manager</p>
              <p>
                <a href="https://www.linkedin.com/in/simon-r-jacobs">LinkedIn</a>
              </p>
              {/* <p className="text-gray-700 mb-6">
                Michael brings a wealth of industry knowledge to our team. With
                his sharp analytical skills and strategic mindset, he excels at
                guiding businesses through complex financial decisions and
                growth strategies.
              </p> */}
            </div>
            <div className="text-center">
              {/* <img
                src={expertAccountant}
                alt="Accounts Manager"
                className="w-64 h-64 object-cover rounded-full mx-auto mb-6"
              /> */}
              <h3 className="text-xl font-semibold mb-2">Jemima Hampton-Williams</h3>
              <p className="text-gray-600 mb-4">Accounts Manager</p>
              <p>
                <a href="https://www.linkedin.com/in/jemima-hampton-williams-1476a51a1">LinkedIn</a>
              </p>
              {/* <p className="text-gray-700 mb-6">
                John is our seasoned CPA with over 15 years of experience in
                complex tax strategies. His expertise in navigating intricate
                tax codes ensures our clients maximize their financial potential
                while maintaining full compliance.
              </p> */}
            </div>
            <div className="text-center">
              {/* <img
                src={expertAccountant}
                alt="Accounts Manager"
                className="w-64 h-64 object-cover rounded-full mx-auto mb-6"
              /> */}
              <h3 className="text-xl font-semibold mb-2">Rebecca Dollery</h3>
              <p className="text-gray-600 mb-4">Accounts Manager</p>
              <p>
                <a href="https://www.linkedin.com/in/rebecca-dollery-49791a50">LinkedIn</a>
              </p>
              {/* <p className="text-gray-700 mb-6">
                John is our seasoned CPA with over 15 years of experience in
                complex tax strategies. His expertise in navigating intricate
                tax codes ensures our clients maximize their financial potential
                while maintaining full compliance.
              </p> */}
            </div>
            <div className="text-center">
              {/* <img
                src={expertAccountant}
                alt="Bookkeeper"
                className="w-64 h-64 object-cover rounded-full mx-auto mb-6"
              /> */}
              <h3 className="text-xl font-semibold mb-2">Samantha Clegg</h3>
              <p className="text-gray-600 mb-4">Bookkeeper</p>
              <p>
                <a href="https://www.linkedin.com/in/samantha-clegg-micb-bbba884">LinkedIn</a>
              </p>
              {/* <p className="text-gray-700 mb-6">
                John is our seasoned CPA with over 15 years of experience in
                complex tax strategies. His expertise in navigating intricate
                tax codes ensures our clients maximize their financial potential
                while maintaining full compliance.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Experience the Fox Smith Difference?
          </h2>
          {/* <p className="text-xl mb-12 max-w-2xl mx-auto">
            Let's start a conversation about how we can help you achieve your
            financial goals and business objectives.
          </p> */}
          <Link
            to="/contact"
            className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold"
          >
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;
