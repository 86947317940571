// src/components/legal/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      
      <h2 className="text-xl font-semibold mt-6 mb-3">1. Introduction</h2>
      <p className="mb-4">Fox Smith Associates Ltd ("we," "our," or "us") is committed to protecting and respecting your privacy. This policy explains how we collect, use, and protect your personal information.</p>
      
      <h2 className="text-xl font-semibold mt-6 mb-3">2. Information We Collect</h2>
      <p className="mb-2">We collect information that you provide through our contact form, including:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Name: Fox Smith Associates</li>
        <li>Email address: info@foxsmith.co.uk</li>
        <li>Phone number: 01628 276 130</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">3. How We Use Your Information</h2>
      <p className="mb-2">We use your information solely to:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Respond to your inquiries</li>
        <li>Provide requested services</li>
        <li>Maintain records of our communications</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">4. Data Storage and Security</h2>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Your data is stored securely in our database</li>
        <li>We implement appropriate technical and organizational measures to protect your data</li>
        <li>We retain your information only for as long as necessary to fulfill the purposes we collected it for</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">5. International Data Transfers</h2>
      <p className="mb-2">As we serve clients internationally:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Your data may be transferred to and stored in countries outside the UK</li>
        <li>We ensure appropriate safeguards are in place for such transfers</li>
        <li>We comply with UK and EU data protection laws</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">6. Your Rights</h2>
      <p className="mb-2">You have the right to:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>Access your personal data</li>
        <li>Request correction of your personal data</li>
        <li>Request erasure of your personal data</li>
        <li>Object to processing of your personal data</li>
        <li>Request restriction of processing your personal data</li>
        <li>Request transfer of your personal data</li>
        <li>Withdraw consent</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">7. Future Updates</h2>
      <p className="mb-2">This policy may be updated to reflect:</p>
      <ul className="list-disc list-inside mb-4 ml-4">
        <li>New analytics tools</li>
        <li>Marketing features</li>
        <li>Additional services</li>
      </ul>
      <p className="mb-4">We will notify you of any material changes.</p>

      <h2 className="text-xl font-semibold mt-6 mb-3">8. Contact Information</h2>
      <p className="mb-4">
        Fox Smith Associates Ltd<br />
        43 High Street Marlow SL7 1BA<br />
        asmith@foxsmith.uk<br />
        01628 276 130
      </p>
    </div>
  );
};

export default PrivacyPolicy;