import React from "react";
import { Link } from "react-router-dom";
import foxHeroImage from "../assets/home-hero-fox.jpg";
// Christmas time only
// import foxHeroImage from "../assets/logo_fox_smith_associates_christmas_1_compressed_cropped.png";
// import Snowfall from './Snowfall'; 

const services = [
  {
    title: "Accounting & Strategy",
    description:
      "Partnering with our clients our accounting solutions start with the everyday items, bookkeeping, VAT returns and payroll.",
  },
  {
    title: "Tax",
    description: `Navigating the complex, evolving tax landscape can be especially challenging for business owners and individuals managing significant financial responsibilities.`,
  },
  {
    title:
      "Inheritance Tax / Trust & Estates / Family Wealth Preservation Structuring",
    description:
      "Our tax specialists are both Chartered Tax Advisers (CTA) and members of the Society of Trust & Estate Practitioners (STEP), the global accreditation body for professionals helping families with future planning.",
  },
  {
    title: "Non-UK Domiciled Individuals",
    description:
      "The Autumn 2024 budget has brought significant changes to the tax landscape for UK Resident, Non-UK Domiciled (RND) individuals.",
  },
  {
    title: "Offshore Trusts",
    description:
      "Fox Smith serves as tax advisers for several large offshore Trust Companies, primarily based in the Channel Islands.",
  },
];

const Home = () => {
  return (
    <div className="bg-white">
      {/* Hero Section  - Look into the website legal terms and conditions */}
      <>
    <section className="relative bg-black min-h-screen bg-cover bg-center">
      {/* Christmas time only */}
      {/* <Snowfall /> */}
      <style>
        {`
          @font-face {
            font-family: 'Wide Sans';
            src: url('https://fonts.cdnfonts.com/css/bank-gothic') format('woff2');
            font-weight: 200;
            font-style: normal;
          }

          .title-text {
            font-family: 'Wide Sans', 'Arial', sans-serif;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-weight: 200;
            text-align: center;
            line-height: 1.2;
            white-space: nowrap;
          }

          .title-container {
            display: flex;
            justify-content: center;
            width: 100%;
            overflow: visible;
          }

          .first-letter {
            font-size: 1.3em;
            font-weight: 300;
          }

          .subtitle-text {
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: 0.1em;
            line-height: 1.5;
            font-weight: 210;
          }

          @media (max-width: 480px) {
            .title-text {
              font-size: 1.25rem;
              letter-spacing: 0.15em;
            }
          }
        `}
      </style>
      <div className="flex items-center justify-center min-h-screen pb-16 md:pb-24 lg:pb-32">
        <div className="w-full max-w-xl mx-auto text-center">
          <h1 className="title-text text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white mb-2">
            <div className="font-serif title-container">
              <span>
                <span className="first-letter">F</span>ox{' '}
                <span className="first-letter">S</span>mith{' '}
                <span className="first-letter">A</span>ssociates
              </span>
            </div>
          </h1>
          
          <div className="w-20 h-0.5 bg-orange-650 mx-auto mb-8"></div>

          <p className="subtitle-text text-lg md:text-xl text-gray-300 mb-12 px-4">
            Personalised Service, Expert Tax Solutions
          </p>

          {/* <div className="w-48 h-48 md:w-56 md:h-56 mx-auto mb-12"> */}
          <div className="w-48 h-48 md:w-56 md:h-56 mx-auto mb-12">
            <img
              src={foxHeroImage}
              alt="Fox Smith Associates Logo"
              className="rounded-full shadow-xl opacity-80"
            />
          </div>

          <Link
            to="/contact"
            className="inline-block border border-orange-650 text-orange-650 px-8 py-3
              hover:bg-orange-650 hover:text-black transition duration-300
              tracking-widest text-sm title-text"
          >
            GET IN TOUCH
          </Link>
        </div>
      </div>
    </section>
      </>
      {/* Key Services Section */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center">
            Our Key Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {services.map((service, index) => (
              <div
                key={index}
                className="border border-gray-200 p-8 hover:shadow-xl transition duration-300"
              >
                <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <Link
                  to="/services"
                  className="text-black font-semibold hover:underline"
                >
                  Learn More
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Us Snippet */}
      {/* <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">
              About Fox Smith Associates
            </h2>
            <p className="text-gray-700 mb-12 text-lg leading-relaxed">
            With over 8 years of experience, Fox Smith Associates has been a trusted name in accounting and tax services.
            Our team of expert professionals is committed to delivering exceptional results for our clients, combining deep accounting and tax knowledge with a client-focused approach.
            </p>
            <Link
              to="/about"
              className="text-black font-semibold hover:underline uppercase tracking-wider"
            >
              Learn More About Us
            </Link>
          </div>
        </div>
      </section> */}

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            As you are Ready to Work with Us
          </h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">
            We'll discuss how we can help you navigate your accounting, tax and
            business management challenges to successfully achieve your goals.
          </p>
          <Link
            to="/contact"
            className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold"
          >
            Contact Us Today
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
