import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import heroImage from '../assets/international_hero.jpg';
import StylizedText from "../../src/components/layout/StylizedText";

const International = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedService, setExpandedService] = useState(null);

  const services = [
    {
      title: 'UAE & US Footprint',
      description: 'Specialised expertise in navigating the tax landscapes of the UAE and US markets.',
      details: `Our UAE & US Footprint service offers comprehensive support for businesses and individuals operating in or between these dynamic markets.
      We provide expert guidance on local tax laws, regulatory compliance, and financial best practices.
      Our team assists with tax structuring, cross-border transactions, and optimising your tax strategy to leverage the unique opportunities in both regions.`
    },
    {
      title: 'International Tax Planning',
      description: 'Comprehensive strategies for managing taxes across multiple jurisdictions.',
      details: `Our International Tax Planning service is designed to help you navigate the complexities of international tax. We offer tailored strategies for tax efficiency, finance management, and regulatory compliance across multiple countries. Our experts can help you with transfer pricing, foreign tax credits, treaty benefits, and structuring your global operations to minimise risks and maximise opportunities.`
    },
    {
      title: 'Departure and Arrival Planning',
      description: 'Tailored advice for seamless tax transitions when relocating internationally.',
      details: `Our Departure & Arrival Planning service ensures a smooth tax transition for individuals and businesses moving across borders. We provide comprehensive support for pre-departure tax planning, managing assets and liabilities in multiple countries, and establishing financial structures in your new location. Our team can assist with exit taxes, pension transfers, property considerations, and setting up banking and investment accounts in your destination country.`
    }
  ];

  const toggleExpand = (title) => {
    setExpandedService(expandedService === title ? null : title);
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative bg-black flex justify-center min-h-screen lg:h-auto">
  <div className="absolute h-full lg:relative lg:h-auto w-full flex justify-center">
    <img 
      src={heroImage}
      alt="Hero background"
      className="h-full w-full lg:h-auto lg:w-auto max-w-[100vw] object-cover grayscale"
    />
  </div>
  <div className="absolute inset-0 bg-black/50 lg:w-full"></div>
  <div className="relative h-full lg:absolute lg:inset-0 flex items-start w-full">
    <div className="container mx-auto px-4 pt-[21vh] text-white">
      <div className="max-w-3xl px-6 py-3">
        <h1 className="text-1xl font-bold mb-6 leading-tight">
          <StylizedText text="International" />
          <StylizedText text="Services" />
        </h1>
        <p className="text-xl mb-12 max-w-2xl">
          Expert guidance for your global financial needs, specialising in UAE & US markets, cross-border planning, and relocation services.
        </p>
        <Link 
          to="/contact" 
          className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold inline-block"
        >
          Enquire Now
        </Link>
      </div>
    </div>
  </div>
</section>
      {/* Services Section */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="font-serif text-3xl font-bold mb-16 text-center">Our International Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {services.map((service, index) => (
              <div key={index} className="border border-gray-200 p-8 hover:shadow-xl transition duration-300">
                <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                {expandedService === service.title && (
                  <div className="mt-4 mb-6 text-gray-700">
                    {service.details}
                  </div>
                )}
                <button
                  onClick={() => toggleExpand(service.title)}
                  className="text-black font-semibold hover:underline"
                >
                  {expandedService === service.title
                    ? "Show Less"
                    : "Learn More"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Our International Services */}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Global Expertise, Local Knowledge</h2>
            <p className="text-gray-700 mb-12 text-lg leading-relaxed">
            At Fox Smith Associates, we understand the complexities of international tax.
            Our team of experts combine knowledge of global markets with localised insights, ensuring that your tax interests are protected and optimised across borders.
            Whether you're expanding your business internationally, planning a move abroad, or managing cross-border investments, we provide the guidance and support you need to succeed in today's interconnected world.
            </p>
            <Link to="/about" className="text-black font-semibold hover:underline uppercase tracking-wider">
              Discover Our Global Reach
            </Link>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Go Global?</h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">Let's discuss how we can help you navigate the complexities of international finance and achieve your global objectives.</p>
          <Link to="/contact" className="bg-white text-black px-8 py-3 hover:bg-gray-200 transition duration-300 uppercase tracking-wider text-sm font-semibold">
            Schedule a Consultation
          </Link>
        </div>
      </section>
    </div>
  );
};

export default International;